<template>
  <div class="c-game">
    <div v-if="!loaded" class="loader">
      <LoaderIcon />
    </div>
    <Countdown v-if="!running" class="c-game__countdown" @start="running = true" />
    <div v-else class="c-game__content">
      <h1 class="c-game__title">{{ copy.title }}</h1>
      
      <div class="c-game__question">
        <Counter v-if="running" class="counter" @ended="ended" />
        <p>vraag {{ score + 1 }} / {{ config.numberOfQuestions }}</p>
        <p>{{ currentQuestion?.question }}</p>
      </div>

      <div class="c-game__answers">
        <button v-for="answer in currentQuestion?.answers" :key="answer" class="c-button c-button__answer" @click="selectAnswer(answer)">{{ answer }}</button>
      </div>
     
      <button v-if="skipsLeft > 0" class="c-button c-button--outline" @click="skipQuestion">{{ copy.skip }}</button>

    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import { CAMPAIGN_CONFIG as config } from '../config';
import Counter from './Counter.vue';
import Countdown from './Countdown.vue';
import LoaderIcon from '../assets/icons/loader.svg';

const copy = config.copy.game

const running = ref(false)
const questions = ref([])
const loaded = ref(false)
const currentQuestion = ref(null)
const score = ref(0)
const skipsLeft = ref(config.numberOfSkips)

const emit = defineEmits(['setScore'])

const selectAnswer = (answer) => {
  if (answer === currentQuestion.value.correct_answer) {
    score.value++
    nextQuestion()
  } else {
    emit('setScore', score.value)
  }
}

const nextQuestion = () => {
  if (questions.value.length === 0) {
    emit('setScore', score.value)
  } else {
    currentQuestion.value = questions.value.shift()
  }
}

const skipQuestion = () => {
  questions.value.push(currentQuestion.value)
  skipsLeft.value--
  nextQuestion()
}

onBeforeMount(async () => {
  await fetchQuestions()
})

const ended = () => {
  emit('setScore', score.value)
}

const fetchQuestions = async () => {
  const response = await fetch(config.questionsUrl)

  const data = await response.json()
  let allQuestions = data.reduce((acc, item) => {
    const question = {
      question: item["Vraag"],
      correct_answer: item["Juist antwoord"],
      answers: [
        item["Antwoord 1"],
        item["Antwoord 2"],
        item["Antwoord 3"],
        item["Antwoord 4"]
      ]
    }

    question.answers = shuffleArray(question.answers)
    acc.push(question)
    return acc
  }, [])

  allQuestions = shuffleArray(allQuestions)
  questions.value = allQuestions.slice(0, config.numberOfQuestions)
  loaded.value = true
  currentQuestion.value = questions.value.shift()
}

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}
</script>

<style lang="scss" scoped>
.c-game {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  padding: 0 20px 20px;
  box-sizing: border-box;

  &__content {
    width: 100%;
    padding: 0 20px;
  }

  &__title {
    margin-bottom: 30px;
  }

  &__question {
    background-color: var(--background-alternate-color);
    color: var(--text-alternate-color);
    border-radius: 5px;
    padding: 20px 10px 5px 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .counter {
      position: absolute;
      top: -30px;
      width: 40px;
      height: 40px;
      background: var(--background-alternate-color);
      padding: 5px;
      border-radius: 50%;
    }
  }

  &__answers {
    display: flex;
    flex-direction: column;
    margin: 10px 0 15px 0;

    .c-button__answer {
      padding: 7px 15px 7px 10px;
      border-radius: 5px;
      font-size: 1rem;
      color: #333;
      font-family: var(--font-body);
      text-transform: initial;
      font-weight: normal;
      align-items: center;
      justify-content: flex-start;
      max-width: 100%;
      margin: 0;
    }
  }

  .c-button {
    margin: 5px 0;
  }

  &__countdown {
    min-height: var(--min-height);
  }
}

.loader {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: calc(100% - 40px);
  min-height: calc(var(--min-height) - 40px);
  width: 100%;
  z-index: 1;
  fill: white;
  background-color: var(--background-color);

  svg {
    width: 50px;
    height: 50px;
    animation: spin 5s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
