import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/index.scss'
import './assets/css/themes.scss'
import { dataLayer, configuration, hybrid } from '@dpgradio/creative';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { authentication } from '@dpgradio/creative';

window.createVueApp = async () => {
  const app = createApp(App).use(hybrid).use(autoAnimatePlugin)

  const element = document.getElementById('verdubbel-je-jaarloon')
  const station = element.getAttribute('data-station') ?? 'qmusic_be'
  await configuration.retrieveConfigForStation(station)

  // DATALAYER
  dataLayer.initialize()
  authentication.initialize()
  app.mount('#verdubbel-je-jaarloon')
}


window.createVueApp()