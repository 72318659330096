<template>
  <div v-auto-animate class="o-quiz-client" :class="[config.theme, { inline: config.inline }]"
    :style="componentStyling">
    <div class="o-body">
      <Preview v-if="view === 'preview'" @changeView="changeView" />
      <Game v-else-if="view === 'game'" @setScore="setScore" />
      <Result v-else-if="view === 'results'" :score="lastScore" @changeView="changeView" />
      <QuestionForm v-else-if="view === 'form'" :profile="profile" />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { CAMPAIGN_CONFIG as config } from "./config"
import { api, authentication, dataLayer } from "@dpgradio/creative"
import Preview from './components/Preview.vue';
import Game from './components/Game.vue';
import Result from './components/Result.vue';
import QuestionForm from './components/questionForm/QuestionForm.vue';

const view = ref('preview')
const lastScore = ref(0)
const profile = ref(null)

const changeView = (newView) => {
  view.value = newView
}

const setScore = (score) => {
  lastScore.value = score
  changeView('results')
}

onMounted(() => {
  dataLayer.setCampaignDetails({
    name: 'Verdubbel je loon',
    type: 'quiz',
    station: config.station,
  })
  fetchProfile()
})

const fetchProfile = async () => {
  try {
    if (authentication.isLoggedIn) {
      profile.value = await api.members.me()
    }
  } catch (error) {
    console.error(error)
  }
}

const componentStyling = computed(() => ({
  "--text-color": config.colors.text,
  "--text-alternate-color": config.colors.textAlternate,
  "--button-color": config.colors.button,
  "--button-text-color": config.colors.buttonText,
  "--background-color": config.colors.background,
  "--background-alternate-color": config.colors.backgroundAlternate,
}))
</script>