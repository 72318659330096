<template>
  <div class="c-form">
    <h1>{{ config.copy.form.title }}</h1>
    <div v-if="!submitted" class="form">
      <Question v-for="question in questions" :key="question.id" :question="question"
        :default-value="question.defaultValue?.(profile)" @change="(event) => updateAnswer(question, event.target.value)" />

      <span v-if="errors.length">
        <p class="c-error">Gelieve alle velden in te vullen</p>
        <p>{{ errors }}</p>
      </span>

      <button class="c-button c-button__submit" @click="onSave">Registreer</button>
    </div>
    <span v-if="submitted">
      <p class="c-success">{{ config.copy.form.submitted }}</p>
    </span>

  </div>

</template>

<script setup>
import { onMounted, ref } from 'vue';
import { CAMPAIGN_CONFIG as config } from '../../config';
import Question from './Question.vue';
import { dataLayer } from '@dpgradio/creative';
const questions = config.form.questions;

const props = defineProps({
  profile: Object,
})

const answers = ref({})
const errors = ref([])
const submitted = ref(false)

const updateAnswer = (question, answer) => {
  if (question.type === 'checkbox') {
    answers.value[question.id] = answer ? question.checkboxText : ''
  } else {
    answers.value[question.id] = answer
  }
}

const onSave = () => {
  questions.forEach((question) => {
    if (question.required && !answers.value[question.id]) {
      errors.value.push(question.id)
    }
  })

  if (errors.value.length === 0) {
    submitForm()
  }
}

const prefillAnswers = () => {
  questions.forEach((question) => {
    console.log(props.profile)
    answers.value[question.id] = question.defaultValue?.(props.profile)
  })
}

onMounted(() => {
  prefillAnswers()
})

const submitForm = async () => {
  errors.value = []
  const answerAttributes = Object.assign(
    {},
    Object.entries(answers.value)
      .filter(([, answer]) => {
        return answer
      })
      .map(([id, answer]) => ({
        webform_question_id: id,
        content: answer,
      }))
  )

  try {
    await fetch(`${config.apiBaseUrl}/webform/forms/${config.form.id}/submissions.js`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        webform_submission: {
          answers_attributes: answerAttributes,
        }
      }),
    });

    dataLayer.pushCampaignAction('formSubmitted')
    submitted.value = true
  } catch (error) {
    console.error('Error submitting form', error)
  }
}
</script>

<style lang="scss" scoped>
.c-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  padding: 0 20px 20px;
  box-sizing: border-box;
}

.form {
  width: 100%;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  box-sizing: border-box;

  .c-button__submit {
    margin-top: 20px;
  }
}

.c-success {
  font-size: 1.4rem;
}
</style>