<template>
    <div v-if="visible" class="wrong-screen">
      <XMark />
    </div>
  </template>
  
  <script setup>
  import { onMounted, onUnmounted, ref } from 'vue'
  import XMark from '../assets/icons/x-mark.svg'
  
  let countdownInterval
  const visible = ref(true)
  
  const startCountdown = () => {
    countdownInterval = setInterval(() => {
      visible.value = false
      clearInterval(countdownInterval)
    }, 500)
  }
  
  onMounted(() => {
    startCountdown()
  })
  
  onUnmounted(() => {
    clearInterval(countdownInterval)
  })
  </script>
  
  <style lang="scss" scoped>
  .wrong-screen {
    position: absolute;
    max-height: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    fill: white;
    background-color: var(--background-color);
    z-index: 300;
    padding: 50px;
    box-sizing: border-box;
    border-radius: 15px;

    svg {
      width: 100px;
      height: 100px;
    }
  }
  </style>